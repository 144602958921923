<template>
  <div>
    <div class="MyTopic_box">
      <div class="MyTopic_boxItem" v-for="(item,index) in topicList" :key="index" @click="openTopic(item.id)">
        <div class="MyTopic_boxItem_rightImg">
          <img :src="item.cover" />
        </div>
        <div class="MyTopic_boxItem_rightNum">
          <div class="MyTopicOmit">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      currentPage: 1,
      topicList: [],
    }
  },
  created(){
    this.getMyTopic();
  },
  methods:{
    getMyTopic(){
      this.$axios({
        url: this.$api + "/auth/user/creation",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: this.currentPage,
          cat: 3,
        },
      })
      .then((res) => {
        if(res.data.code == 200){
          this.topicList = res.data.data.list;
        }
      })
    },
    openTopic(e) {
      this.$router.push({
        path: "/mTopicDetails",
        query: {
          id: e,
        },
      });
    },
  }
};
</script>

<style scoped>
.MyTopicOmit {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MyTopic_title {
  padding: 3% 0;
  color: #fff;
  text-align: center;
  background-color: #606266;
}
.MyTopic_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.MyTopic_boxItem {
  margin-top: 3%;
  width: 100%;
  position: relative;
}
.MyTopic_boxItem_rightImg {
  width: 100%;
  height: 10rem;
}
.MyTopic_boxItem_rightImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.MyTopic_boxItem_rightNum {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.5rem;
  background-color: #00000041;
  color: #fff;
  font-size: 14px;
  padding: 5%;
  bottom: 0;
  box-sizing: border-box;
}
</style>